import React from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'

import * as actions from '../../actions'

import ContactForm from '../forms/ContactForm'
import AvatarBox from '../AvatarBox'

@connect(state => ({
  user: state.user.user,
  rental: state.rental.listing,
  sale: state.sale.listing,
  building: state.building.building,
  contactPeople: (state.rental.listing && state.rental.listing.users) ||
    (state.sale.listing && state.sale.listing.agents) ||
    (state.user.roommate && [state.user.roommate]) ||
    (state.building.building && (state.building.building.building_expert ? [state.building.building.building_expert] : [state.building.building.pro_user])) ||
    [],
  createConversationPending: state.conversation.createConversationPending,
}), actions)
@autobind
export default class ContactModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  handleContactSubmit(values) {
    const {
      user,
      contactPeople,
      createConversation,
      rental,
      sale,
      building,
    } = this.props

    let newConversation

    let pro_users = contactPeople.filter(user => user.is_pro).map(user => user.id)
    let users = contactPeople.filter(user => !user.is_pro).map(user => user.id)

    if (user) {
      if (user.is_pro) {
        pro_users.push(user.id)
      } else {
        users.push(user.id)
      }
    }

    if (user) {
      newConversation = {
        conversation: {
          pro_users: pro_users,
          users: users,
          lease_detail_id: rental && rental.id,
          unit_sale_id: sale && sale.id,
          building_id: building && building.id,
        },
        message: {
          content: values.message,
          sent_by: user.id,
        },
      }
    } else {
      newConversation = {
        conversation: {
          pro_users: pro_users,
          users: users,
          lease_detail_id: rental && rental.id,
          unit_sale_id: sale && sale.id,
          building_id: building && building.id,
        },
        message: {
          content: values.message,
        },
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        phone_nr: values.phone_nr,
      }
    }

    createConversation(newConversation)
  }

  render() {
    const {
      rental,
      sale,
      user,
      building,
      createConversationPending,
      setContactModalVisibility,
      contactPeople,
    } = this.props

    let defaultMessage = ''

    if (building) {
      defaultMessage = `Hi,\nI’m interested in finding out more about listing availability at ${building.name}.`
    } else if (rental || sale) {
      defaultMessage = 'Hi,\nI found your listing on PropertyClub and am interested in finding out more about it.'
    }

    return (
      <div className="modal-container">
        <i className="close-icon nc-icon-outline ui-1_circle-remove visible-xs"
          onClick={setContactModalVisibility.bind(this, false)}></i>
        <div className="primary uppercase bold">To</div>
        <AvatarBox
          contactPeople={contactPeople}
          isPro={(rental && rental.is_pro_user) || (sale && sale.id) || (building && building.id)}
          />
        <ContactForm
          user={user}
          submitPending={createConversationPending}
          onSubmit={this.handleContactSubmit}
          initialValues={{
            message: defaultMessage,
          }} />
      </div>
    )
  }
}
