import React, { Component } from 'react'
import { Form, Field, reduxForm } from 'redux-form'

import {
  renderPlaceholderTextareaField,
  renderRatingField,
} from './fields'

const validate = (values, props) => {
  const errors = {}

  if (!values.broker_stars_review) {
    errors.broker_stars_review = 'Required'
  }

  if (!values.listing_stars_review) {
    errors.listing_stars_review = 'Required'
  }

  if (!values.building_stars_review) {
    errors.building_stars_review = 'Required'
  }

  return errors
}

class ViewingReviewForm extends Component {
  render () {
    const {
      handleSubmit,
      requestViewingPending,
    } = this.props

    return <div>
      <Form className="form-content login-form pt-10"
        onSubmit={handleSubmit}
        noValidate>
        <div className="visible-xs pb-20"></div>
        <div className="justify-between w-100 pb-10">
          <div className="primary uppercase bold">Broker</div>
          <Field name="broker_stars_review" component={renderRatingField} />
        </div>
        <Field name="broker_text_review" component={renderPlaceholderTextareaField} placeholder="How satisfied were you with the broker?" />
        <div className="justify-between w-100 pt-20 pb-10">
          <div className="primary uppercase bold">Listing</div>
          <Field name="listing_stars_review" component={renderRatingField} />
        </div>
        <Field name="listing_text_review" component={renderPlaceholderTextareaField} placeholder="Did the listing match the apartment specifications?" />
        <div className="justify-between w-100 pt-20 pb-10">
          <div className="primary uppercase bold">Building</div>
          <Field name="building_stars_review" component={renderRatingField} />
        </div>
        <Field name="building_text_review" component={renderPlaceholderTextareaField} placeholder="What impression did the building leave on you?" />
        <div className="right">
          <button className="btn mt-15" type="submit" disabled={requestViewingPending}>Send</button>
        </div>
      </Form>
    </div>
  }
}

export default reduxForm({
  form: 'viewingReview',
  validate,
  fields: ['broker_stars_review', 'broker_text_review', 'listing_stars_review', 'listing_text_review', 'building_stars_review', 'building_text_review'],
  initialValues: {
  },
})(ViewingReviewForm)
