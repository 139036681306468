import React from 'react'
import Slick from 'react-slick'
import autobind from 'autobind-decorator'
import classnames from 'classnames'

import UserAvatar from './UserAvatar'

@autobind
export default class AvatarBox extends React.Component {
  render() {
    const {
      contactPeople,
      isPro,
      horizontal,
    } = this.props

    const slickConfig = {
      arrows: false,
      draggable: true,
      dots: true,
      autoplay: true,
      autoplaySpeed: 5000,
    }

    if (!contactPeople) {
      return null
    }

    if (contactPeople.length > 1) {
      return (
        <Slick
          className="multiple-users-carousel"
          {...slickConfig}>
          {
            contactPeople.map((contactPerson, index) => (
              <div key={index}>
                <div className={classnames('avatar-box', {
                    'avatar-box-horizontal': horizontal,
                  })}
                  key={index}>
                  <UserAvatar user={contactPerson} />
                  <div className="name">
                    {
                      isPro ?
                        <p className="title" target="_blank">{contactPerson.first_name + ' ' + contactPerson.last_name}</p>
                        :
                        <p className="title">{contactPerson.first_name + ' ' + contactPerson.last_name}</p>
                    }
                    <p className="subtitle">{isPro ? (contactPerson.title || 'RE SALESPERSON') + (contactPerson.agency ? ' - ' + contactPerson.agency : '')  : 'Tenant'}</p>
                  </div>
                </div>
              </div>
            ))
          }
        </Slick>
      )
    } else if (contactPeople[0]) {
      return (
        <div className="avatar-box">
          <UserAvatar user={contactPeople[0]} />
          <div className="name">
            {
              isPro ?
                <p className="title" target="_blank">{contactPeople[0].first_name + ' ' + contactPeople[0].last_name}</p>
                :
                <p className="title">{contactPeople[0].first_name + ' ' + contactPeople[0].last_name}</p>
            }
            <p className="subtitle">{isPro ? (contactPeople[0].title || 'RE SALESPERSON') + (contactPeople[0].agency ? ' - ' + contactPeople[0].agency : '')  : 'Tenant'}</p>
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}
