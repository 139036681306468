import React, { Component } from 'react'
import { Form, Field, reduxForm } from 'redux-form'

import * as helpers from '../../helpers'

import {
  renderPlaceholderTextField,
  renderPlaceholderTextareaField,
  renderPlaceholderPhoneField,
} from './fields'

const validate = (values, props) => {
  const errors = {}

  if (!props.user) {
    if (!values.first_name) {
      errors.first_name = 'First name is required'
    }

    if (!values.last_name) {
      errors.last_name = 'Last name is required'
    }

    if (!values.email) {
      errors.email = 'Email is required'
    } else if (values.email && !helpers.isEmailValid(values.email)) {
      errors.email = 'Email is not valid'
    }

    if (!values.phone_nr) {
      errors.phone_nr = 'Phone number is required'
    } else if (values.phone_nr.length < 17) {
      errors.phone_nr = 'Phone number is not valid'
    }
  }

  if (!values.message) {
    errors.message = 'Message is required'
  }

  return errors
}

class ContactForm extends Component {
  render () {
    const {
      handleSubmit,
      submitPending,
      user,
    } = this.props

    return <div>
      <Form className="form-content login-form"
        onSubmit={handleSubmit}
        noValidate>
          {
            !user ?
              <div>
                <div className="primary uppercase bold pt-30 pb-15 hidden-xs">From</div>
                <div className="primary uppercase bold pt-15 pb-10 visible-xs">From</div>
                <div className="mb-15">
                  <Field name="first_name" component={renderPlaceholderTextField} placeholder="First name" type="text" />
                </div>
                <div className="mb-15">
                  <Field name="last_name" component={renderPlaceholderTextField} placeholder="Last name" type="text" />
                </div>
                <div className="mb-15">
                  <Field name="email" component={renderPlaceholderTextField} placeholder="Email" type="email" />
                </div>
                <div className="mb-15">
                  <Field name="phone_nr" component={renderPlaceholderPhoneField} placeholder="Phone number" type="text" />
                </div>
              </div>
              : <div className="visible-xs pt-15"></div>
          }
          <div>
            <div className="primary uppercase bold pt-30 pb-15 hidden-xs">Message</div>
            <Field name="message" component={renderPlaceholderTextareaField} placeholder="Say something nice..." />
          </div>
          <div className="center">
            <button className="btn mt-20 btn-md btn-round pl-50 pr-50" type="submit" disabled={submitPending}>Send</button>
          </div>
      </Form>
    </div>
  }
}

export default reduxForm({
  form: 'contact',
  validate,
  fields: [],
})(ContactForm)
