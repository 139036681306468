import React from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'
import Modal from 'react-modal'

import * as actions from '../../actions'

import LoginModal from './LoginModal'
import SignupModal from './SignupModal'
import ForgotPasswordModal from './ForgotPasswordModal'
import ContactModal from './ContactModal'
import ListingPhotoCarouselModal from './ListingPhotoCarouselModal'
import ListingShareModal from './ListingShareModal'
import RequestViewingModal from './RequestViewingModal'
import CreditCardModal from './CreditCardModal'
import ViewingReviewModal from './ViewingReviewModal'
import BuildingReviewModal from './BuildingReviewModal'

// Modal.setAppElement('#app')

@connect(state => ({
  modal: state.modal,
}), actions)
@autobind
export default class Modals extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    const {
      modal,
      setLoginModalVisibility,
      setSignupModalVisibility,
      setForgotPasswordModalVisibility,
      setContactModalVisibility,
      setListingPhotoCarouselModalVisibility,
      setListingShareModalVisibility,
      setCreditCardModalVisibility,
      setRequestViewingModalVisibility,
      setViewingReviewModalVisibility,
      setBuildingReviewModalVisibility,
    } = this.props

    const authClassName = {
      base: 'react-modal-auth',
      afterOpen: 'react-modal-auth_after-open',
      beforeClose: 'react-modal-auth_before-close',
    }

    const regularClassName = {
      base: 'react-modal-regular',
      afterOpen: 'react-modal-regular_after-open',
      beforeClose: 'react-modal-regular_before-close',
    }

    const listingPhotoCarouselClassName = {
      base: 'react-modal-listing-photo-carousel',
      afterOpen: 'react-modal-listing-photo-carousel_after-open',
      beforeClose: 'react-modal-listing-photo-carousel_before-close',
    }

    const overlayHideResponsiveClassName = {
      base: 'react-modal-overlay react-modal-overlay-hide-mobile',
      afterOpen: 'react-modal-overlay_after-open react-modal-overlay-hide-mobile',
      beforeClose: 'react-modal-overlay_before-close react-modal-overlay-hide-mobile',
    }

    const overlayClassName = {
      base: 'react-modal-overlay',
      afterOpen: 'react-modal-overlay_after-open',
      beforeClose: 'react-modal-overlay_before-close',
    }

    const overlayBlackClassName = {
      base: 'react-modal-overlay react-modal-overlay-black',
      afterOpen: 'react-modal-overlay_after-open',
      beforeClose: 'react-modal-overlay_before-close',
    }

    return (
      <div>
        <Modal
          isOpen={modal.loginModalVisible}
          onRequestClose={setLoginModalVisibility.bind(this, false)}
          className={authClassName}
          overlayClassName={overlayHideResponsiveClassName}>
          <LoginModal />
        </Modal>
        <Modal
          isOpen={modal.signupModalVisible}
          onRequestClose={setSignupModalVisibility.bind(this, false)}
          className={authClassName}
          overlayClassName={overlayHideResponsiveClassName}>
          <SignupModal />
        </Modal>
        <Modal
          isOpen={modal.forgotPasswordModalVisible}
          onRequestClose={setForgotPasswordModalVisibility.bind(this, false)}
          className={authClassName}
          overlayClassName={overlayHideResponsiveClassName}>
          <ForgotPasswordModal />
        </Modal>
        <Modal
          isOpen={modal.contactModalVisible}
          onRequestClose={setContactModalVisibility.bind(this, false)}
          className={regularClassName}
          overlayClassName={overlayClassName}>
          <ContactModal />
        </Modal>
        <Modal
          isOpen={modal.requestViewingModalVisible}
          onRequestClose={setRequestViewingModalVisibility.bind(this, false)}
          className={regularClassName}
          overlayClassName={overlayClassName}>
          <RequestViewingModal />
        </Modal>
        <Modal
          isOpen={modal.viewingReviewModalVisible}
          onRequestClose={setViewingReviewModalVisibility.bind(this, false)}
          className={regularClassName}
          overlayClassName={overlayClassName}>
          <ViewingReviewModal />
        </Modal>
        <Modal
          isOpen={modal.buildingReviewModalVisible}
          onRequestClose={setBuildingReviewModalVisibility.bind(this, false)}
          className={regularClassName}
          overlayClassName={overlayClassName}>
          <BuildingReviewModal />
        </Modal>
        <Modal
          isOpen={modal.listingPhotoCarouselModalVisible}
          onRequestClose={setListingPhotoCarouselModalVisibility.bind(this, false)}
          className={listingPhotoCarouselClassName}
          overlayClassName={overlayBlackClassName}>
          <ListingPhotoCarouselModal />
        </Modal>
        <Modal
          isOpen={modal.listingShareModalVisible}
          onRequestClose={setListingShareModalVisibility.bind(this, false)}
          className={regularClassName}
          overlayClassName={overlayClassName}>
          <ListingShareModal />
        </Modal>
        <Modal
          isOpen={modal.creditCardModalVisible}
          onRequestClose={setCreditCardModalVisibility.bind(this, false)}
          className={regularClassName}
          overlayClassName={overlayClassName}>
          <CreditCardModal />
        </Modal>
      </div>
    )
  }
}
