import React from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'

import * as actions from '../../actions'

import ForgotPasswordForm from '../forms/ForgotPasswordForm'

@connect(state => ({
}), actions)
@autobind
export default class ForgotPasswordModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  handleForgotPasswordSubmit(values) {
    const {
      sendForgotPassword,
    } = this.props

    sendForgotPassword(values)
  }

  render() {
    const {
      setLoginModalVisibility,
      setForgotPasswordModalVisibility,
    } = this.props

    return (
      <div className="modal-container">
        <div className="auth-form-container mb-30">
          <div className="modal-title">
            Forgot Password
          </div>
          <ForgotPasswordForm
            onSubmit={this.handleForgotPasswordSubmit}/>
        </div>
        <div>
          Back to&nbsp;
          <span
            className="link"
            onClick={() => {
              setLoginModalVisibility(true)
              setForgotPasswordModalVisibility(false)
            }}>Login</span>
        </div>
      </div>
    )
  }
}
