import React from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'
import Slick from 'react-slick'

import * as actions from '../../actions'

import FallbackImage from '../FallbackImage'

@connect(state => ({
  photos: (state.rental.listing && state.rental.listing.photos.map(photo => photo.path)) ||
    (state.sale.listing && state.sale.listing.photos) || (state.building.building && state.building.building.photos.map(photo => photo.path)),
  listingPhotoCarouselIndex: state.app.listingPhotoCarouselIndex || 0,
}), actions)
@autobind
export default class ListingPhotoCarousel extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
    }
  }

  render() {
    const {
      photos,
      setListingPhotoCarouselModalVisibility,
      listingPhotoCarouselIndex,
    } = this.props

    const carouselSettings = {
      enabled: false,
      autoplay: false,
      draggable: true,
      arrows: true,
      dots: true,
      slidesToShow: 1,
      fade: true,
      initialSlide: listingPhotoCarouselIndex,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            fade: false,
            arrows: false
          }
        },
      ],
    }

    return (
      <div className="listing-carousel-modal">
        <Slick
          className="detail-carousel"
          {...carouselSettings}
          >
          {
            photos.map((photo, index) => (
              <div
                key={index}
                className="slide">
                <div className="close-background"
                  onClick={setListingPhotoCarouselModalVisibility.bind(this, false)}></div>
                <FallbackImage src={photo} />
              </div>
            ))
          }
        </Slick>
        <i className="close fa fa-times-circle"
          onClick={setListingPhotoCarouselModalVisibility.bind(this, false)}></i>
      </div>
    )
  }
}