import React from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'
import moment from 'moment'

import * as actions from '../../actions'

import RequestViewingForm from '../forms/RequestViewingForm'

@connect(state => ({
  user: state.user.user,
  rental: state.rental.listing,
  sale: state.sale.listing,
  contactPeople: state.rental.listing ? state.rental.listing.users : (state.sale.listing ? state.sale.listing.agents : []),
  getViewingsPending: state.viewing.getViewingsPending,
}), actions)
@autobind
export default class RequestViewingModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  handleSubmit(values) {
    const {
      createViewing,
      rental,
      sale,
    } = this.props

    createViewing({
      lease_detail_id: rental && rental.id,
      unit_sale_id: sale && sale.id,
      date: moment(values.date).utc().format(),
      message: values.message,
    })
  }

  render() {
    const {
      user,
      getViewingsPending,
      setRequestViewingModalVisibility,
    } = this.props

    return (
      <div className="modal-container">
        <i className="close-icon nc-icon-outline ui-1_circle-remove visible-xs"
          onClick={setRequestViewingModalVisibility.bind(this, false)}></i>
        <RequestViewingForm
          user={user}
          submitPending={getViewingsPending}
          onSubmit={this.handleSubmit} />
      </div>
    )
  }
}
