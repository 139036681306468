import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'

import * as helpers from '../../helpers'

import {
  renderPlaceholderTextField,
  renderPlaceholderPhoneField,
} from './fields'

const validate = (values, props) => {
  const errors = {}

  if (!values.first_name) {
    errors.first_name = 'First name is required'
  }

  if (!values.last_name) {
    errors.last_name = 'Last name is required'
  }

  if (!values.email) {
    errors.email = 'Email is required'
  } else if (values.email && !helpers.isEmailValid(values.email)) {
    errors.email = 'Email is not valid'
  }

  if (!values.password) {
    errors.password = 'Password is required'
  } else if (values.password && values.password.length < 6) {
    errors.password = 'Password must be at least 6 characters long'
  }

  if (!values.password_confirmation) {
    errors.password_confirmation = 'Password confirmation is required'
  } else if (values.password_confirmation !== values.password) {
    errors.password_confirmation = 'Password confirmation is wrong'
  }

  if (!values.phone_nr) {
    errors.phone_nr = 'Phone number is required'
  }

  return errors
}

class SignupForm extends Component {
  render () {
    const {
      handleSubmit,
      signupFetching,
    } = this.props

    return <div>
      <form className="form-content login-form"
        onSubmit={handleSubmit}
        noValidate>
        <div className="row mb-20">
          <div className="col-md-6">
            <div className="mb-20">
              <Field name="first_name" size="lg" component={renderPlaceholderTextField} placeholder="First Name" type="text" autoFocus/>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-20">
              <Field name="last_name" size="lg" component={renderPlaceholderTextField} placeholder="Last Name" type="text"/>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-20">
              <Field name="email" size="lg" component={renderPlaceholderTextField} placeholder="Email" type="email"/>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-20">
              <Field name="phone_nr" size="lg" component={renderPlaceholderPhoneField} placeholder="Phone number" type="text" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-20">
              <Field name="password" size="lg" component={renderPlaceholderTextField} placeholder="Password" type="password"/>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-20">
              <Field name="password_confirmation" size="lg" component={renderPlaceholderTextField} placeholder="Confirm Password" type="password"/>
            </div>
          </div>
        </div>
        <div>
          <button
            className="btn btn-lg btn-round btn-shadow btn-accent"
            disabled={signupFetching}>Sign up</button>
        </div>
      </form>
    </div>
  }
}

export default reduxForm({
  form: 'signup',
  validate,
  fields: ['first_name', 'last_name', 'email', 'password', 'password_confirmation'],
  enableReinitialize: true,
})(SignupForm)
