/* global swal, __CLIENT__ */

import React from 'react'
import classnames from 'classnames'
import MaskedInput from 'react-text-mask'
import classNames from 'classnames'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'

import * as helpers from '../../../helpers'

export const renderPlaceholderTextField = ({input, symbol, size, showError, meta: { dirty, touched, error }, ...props}) => (
  <div className={classnames({
      'form-control-symbol': symbol,
    })}>
    <input
      className={
        classnames('form-control', {
          error: (showError || touched) && error,
          'form-control-md': size === 'md',
          'form-control-lg': size === 'lg',
        })
      }
      {...input}
      {...props} />
    {symbol ? <div className="symbol">{symbol}</div> : null}
  </div>
)

export const renderPlaceholderTextareaField = ({input, label, size, showError, description, meta: { dirty, touched, error }, ...props}) => (
  <div>
    <textarea
      className={
        classnames('form-control', {
          error: (showError || touched) && error,
          'form-control-md': size === 'md',
          'form-control-lg': size === 'lg',
        })
      }
      {...input}
      {...props} />
      {
        description ? <div className="form-control-description f-14">{description}</div> : null
      }
  </div>
)

export const renderSwitchField = ({input, firstOptionLabel, secondOptionLabel, firstOptionStyle, secondOptionStyle, containerStyle, showError, meta: { dirty, touched, error }, ...props}) => (
  <div
    className="switch-container"
    style={containerStyle || {}}>
    <div
      onClick={() => { input.onChange(firstOptionLabel) }}
      className={classnames('switch-option', {
        active: input.value === firstOptionLabel,
      })}
    >{firstOptionLabel}</div>
    <div
      onClick={() => { input.onChange(secondOptionLabel) }}
      className={classnames('switch-option', {
        active: input.value === secondOptionLabel,
      })}
    >{secondOptionLabel}</div>
    <div
      className="switch-active-panel"
      style={input.value === firstOptionLabel ? firstOptionStyle : secondOptionStyle}
    ></div>
  </div>
)

export const renderRatingField = ({input, label, icon, showError, meta: { dirty, touched, error }, ...props}) => (
  <div className={classnames('rating-input', {
      error: (showError || touched) && error,
    })}>
    {
      label ? (
        <div className="rating-input-label">
          {
            icon ? <div className="rating-input-label-icon">{icon}</div> : null
          }
          <div className="rating-input-label-text">{label}</div>
        </div>
      ) : null
    }
    <div className="stars-container">
      {
        [1, 2, 3, 4, 5].map((val, index) => (
          <div className="star" key={val}>
            <svg width="32px" height="31px" viewBox="0 0 32 31" version="1.1" xmlns="http://www.w3.org/2000/svg"
              className={classNames('star-icon', {
                'star-icon-selected': input.value && (6 - input.value) <= val
              })}
              onClick={() => { input.onChange(6 - val) }}>
              <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g className="star-icon-color" id="Review-new-form" transform="translate(-611.000000, -500.000000)" fill="#D9D8E0">
                  <g id="Group-12" transform="translate(297.000000, 500.000000)">
                    <g id="Group" transform="translate(230.000000, 0.603093)">
                      <path d="M101.001813,0.621177363 L105.175473,9.07585912 L114.507831,10.4339763 C114.928351,10.494612 115.277885,10.7889228 115.409244,11.1929751 C115.540604,11.5970274 115.430966,12.0406174 115.126499,12.3369507 L108.372148,18.9128142 L109.966459,28.2075961 C110.038189,28.6263979 109.865998,29.0496142 109.522255,29.2993759 C109.178512,29.5491376 108.722802,29.5821511 108.34665,29.3845415 L100.000671,25.0028691 L91.6533503,29.3885675 C91.2768995,29.5852915 90.8214826,29.5516777 90.4779938,29.3018159 C90.1345049,29.0519541 89.9622921,28.6290153 90.0335406,28.2102801 L91.627852,18.9208663 L84.8735007,12.3450027 C84.5690336,12.0486695 84.4593958,11.6050795 84.5907555,11.2010272 C84.7221153,10.7969748 85.0716488,10.5026641 85.4921687,10.4420283 L94.824527,9.07720113 L98.9981873,0.622519376 C99.1865162,0.241531325 99.5745898,0.000284909677 99.9995835,2.52177119e-07 C100.424577,-0.000284405323 100.812974,0.240441936 101.001813,0.621177363 Z" id="Path"></path>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>
        ))
      }
    </div>
  </div>
)

const AutocompleteItem = ({ formattedSuggestion }) => (
  <div>
    <strong>{ formattedSuggestion.mainText }</strong>{' '}
    <small>{ formattedSuggestion.secondaryText }</small>
  </div>
)

export const renderGoogleAutocompleteField = ({ input, placeholder, size, onSelect, showError, meta: { touched, error }, ...props }) => {
  const id = input.name

  const cssClasses = {
    root: 'google-autocomplete-root',
    input: classnames('form-control', {
      error: (showError || touched) && error,
      'form-control-md shadow': size === 'md',
      'form-control-lg shadow': size === 'lg',
    }),
    autocompleteContainer: 'google-autocomplete-container',
    autocompleteItem: 'google-autocomplete-item',
    autocompleteItemActive: 'google-autocomplete-item-active',
  }

  return (
    <div>
      <PlacesAutocomplete
        id={id}
        inputProps={{
          value: input.value,
          onChange: address => {
            if (!address) {
              onSelect(null)
            }

            input.onChange(address)
          },
          placeholder: placeholder,
          onBlur: input.onBlur,
        }}
        onSelect={(address => {
          geocodeByAddress(address)
          .then(results => {

            let error = 'Invalid address',
              place = results[0],
              i = 0,
              locality,
              administrative_area_level_1,
              street_number,
              addressComponents

            while (error && place) {
              addressComponents = place.address_components

              locality = helpers.queryAddressComponents(addressComponents, 'locality')
              administrative_area_level_1 = helpers.queryAddressComponents(addressComponents, 'administrative_area_level_1')
              street_number = helpers.queryAddressComponents(addressComponents, 'street_number')

              if ((locality || administrative_area_level_1) !== 'New York') {
                error = 'Please add an address from New York City'

                i++
                place = results[i]
              } else if (!street_number) {
                error = 'Please add street number to your address'

                i++
                place = results[i]
              } else {
                error = ''
              }
            }

            if (error) {
              input.onChange('')
              onSelect(null)
              swal('ERROR', error, 'error')

              return
            }

            const newAddress = {
              number: helpers.queryAddressComponents(addressComponents, 'street_number'),
              street: helpers.queryAddressComponents(addressComponents, 'route'),
              sublocality: helpers.queryAddressComponents(addressComponents, 'sublocality_level_1'),
              neighbourhood: helpers.queryAddressComponents(addressComponents, 'sublocality_level_1'),
              city: helpers.queryAddressComponents(addressComponents, 'locality') || helpers.queryAddressComponents(addressComponents, 'administrative_area_level_1'),
              zipcode: helpers.queryAddressComponents(addressComponents, 'postal_code'),
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            }

            onSelect(newAddress)
            input.onChange(address)

            return getLatLng(results[0])
          })
          .catch(error => {
            swal('ERROR', 'Something went wrong. Please try again.', 'error')
          })
        })}
        inputName={input.name}
        autocompleteItem={AutocompleteItem}
        classNames={cssClasses}
        options={{
          types: ['address'],
          componentRestrictions: {
            country: 'us',
          },
        }}
        {...props}
        />
    </div>
  )
}

export const renderSegmentedControlField = ({input, id, size, values, vertical, label, multiple, allowDeselect, meta: { dirty, touched, error }, ...props}) => (
  <div className={classnames('segmented-control', {
      'segmented-control-lg': size === 'lg',
      'segmented-control-md': size === 'md',
      'segmented-control-vertical': vertical,
    })}>
    {
      values.map((val, i) => (
        <div
          key={i}
          className="segmented-control-option">
          <input
            id={id + '-' + val + '-' + i}
            type="checkbox"
            checked={multiple ?
              input.value.indexOf(val) !== -1
              : val === input.value}
            onChange={() => {
              if (multiple) {
                const newValue = [...input.value]
                const foundItemIndex = newValue.findIndex(item => item === val)

                if (foundItemIndex > -1) {
                  newValue.splice(foundItemIndex, 1)
                } else {
                  newValue.push(val)
                }

                input.onChange(newValue)
              } else {
                if (allowDeselect && val === input.value) {
                  input.onChange(null)
                } else {
                  input.onChange(val)
                }
              }
            }}
            />
          <label htmlFor={id + '-' + val + '-' + i}>{val}</label>
        </div>
      ))
    }
  </div>
)

export const renderCheckboxGroup = ({input, id, items, svgPath, meta: { dirty, touched, error }, ...props}) => (
  <div className="checkbox-group">
    {
      items.map(item => (
        <div
          key={item.id}
          className="checkbox-form-control">
          <input
            id={id + item.id}
            type="checkbox"
            checked={input.value.find(val => val.id === item.id)}
            onChange={() => {
              const newValue = [...input.value]
              const foundItemIndex = newValue.findIndex(val => val.id === item.id)

              if (foundItemIndex > -1) {
                newValue.splice(foundItemIndex, 1)
              } else {
                newValue.push(item)
              }

              input.onChange(newValue)
            }}
            />
          <label htmlFor={id + item.id}>
            <div className="checkmark">
              <i className="checkmark-icon nc-icon-mini ui-1_check-small"></i>
            </div>
            <span>{item.name}</span>
          </label>
        </div>
      ))
    }
  </div>
)

export const renderPlaceholderPhoneField = ({input, size, label, meta: { dirty, touched, error }, ...props}) => (
  <div>
    <MaskedInput
      mask={['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      guide={false}
      className={
        classnames('form-control', {
          error: touched && error,
          'form-control-md': size === 'md',
          'form-control-lg': size === 'lg',
        })
      }
      {...input}
      {...props} />
  </div>
)

export const renderLabelTextField = ({input, label, showErrorField, theme, meta: { dirty, touched, error }, ...props}) => (
  <div className="input-row">
    <label className="input-row-label">{label}</label>
    <input
      className={
        classnames('form-control', {
          error: touched && error
        })
      }
      {...input}
      {...props} />
    {
      showErrorField && touched && error ?
        <span className="input-row-error">{error}</span>
        : null
    }
  </div>
)

export const renderLabelSelectField = ({input, values, label, theme, meta: { dirty, touched, error }, ...props}) => (
  <div className="input-row">
    <label className="input-row-label">{label}</label>
    <select
      className={
        classnames('form-control', {
          error: touched && error
        })
      }
      {...input}
      {...props}>
      {
        values && values.map((val, i) => (
          <option value={val.value} key={val.value}>
            {val.label}
          </option>
        ))
      }
    </select>
  </div>
)

export const renderSelectField = ({input, values, label, theme, meta: { dirty, touched, error }, ...props}) => (
  <div>
    <select
      className={
        classnames('form-control', {
          error: touched && error
        })
      }
      {...input}
      {...props}>
      {
        values && values.map((val, i) => (
          <option value={val.value} key={val.value}>
            {val.label}
          </option>
        ))
      }
    </select>
  </div>
)

export const renderLabelTextarea = ({input, label, meta: { dirty, touched, error }, ...props}) => (
  <div className="input-row">
    <label className="input-row-label">{label}</label>
    <textarea
      className={
        classnames('form-control', {
          error: touched && error
        })
      }
      {...input}
      {...props} />
  </div>
)

export const renderPhoneLabelField = ({input, label, meta: { dirty, touched, error }, ...props}) => (
  <div className="input-row">
    <label>{label}</label>
    <MaskedInput
      mask={['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      guide={false}
      className={
        classnames('form-control', {
          error: touched && error
        })
      }
      {...input}
      {...props} />
  </div>
)

export const renderCheckboxField = ({input, label, meta: { dirty, touched, error }, ...props}) => (
  <div className="checkbox-form-control">
    <input
      id={input.name}
      type="checkbox"
      checked={input.value}
      {...input}
      {...props}
      />
    <label htmlFor={input.name}>
      <div className="checkmark">
        <i className="checkmark-icon nc-icon-mini ui-1_check-small"></i>
      </div>
      <span>{label}</span>
    </label>
  </div>
)
