/* global swal, FB */

import React from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'

import * as actions from '../../actions'

import LoginForm from '../forms/LoginForm'

@connect(state => ({
}), actions)
@autobind
export default class LoginModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  loginWithFacebook() {
    const {
      login,
    } = this.props

    FB.login(response => {
      if (response.authResponse) {
        // Login success
        login({
          facebook_token: response.authResponse.accessToken,
        })
      } else {
        //Login error
        swal('ERROR', 'Facebook login failed, please try again!', 'error')
      }
    }, {scope: 'email, public_profile'})
  }

  handleLoginSubmit(values) {
    const {
      login,
    } = this.props

    login(values)
  }

  render() {
    const {
      setLoginModalVisibility,
      setSignupModalVisibility,
      setForgotPasswordModalVisibility,
    } = this.props

    return (
      <div
        className="modal-container"
        >
        <div className="auth-form-container">
          <div className="modal-title">
            Log In
          </div>
          <LoginForm
            onSubmit={this.handleLoginSubmit}/>
        </div>
        <div className="modal-divider">
          <span>Or login via</span>
        </div>
        <div className="mb-30">
          <button className="btn btn-lg btn-round btn-shadow fb"
            onClick={this.loginWithFacebook}>
            <svg width="12px" height="20px" viewBox="0 0 12 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Log-In" transform="translate(-737.000000, -654.000000)" fill="#FFFFFF" fillRule="nonzero">
                        <g id="002-facebook-logo-copy" transform="translate(737.000000, 654.000000)">
                            <path d="M11.5481152,0.00416133496 L8.66986088,0 C5.43624084,0 3.34653351,1.93189975 3.34653351,4.92202699 L3.34653351,7.191411 L0.452577498,7.191411 C0.20250534,7.191411 0,7.37409361 0,7.5994299 L0,10.8875087 C0,11.112845 0.202736247,11.2953195 0.452577498,11.2953195 L3.34653351,11.2953195 L3.34653351,19.5921892 C3.34653351,19.8175255 3.54903885,20 3.79911101,20 L7.57490042,20 C7.82497258,20 8.02747792,19.8173174 8.02747792,19.5921892 L8.02747792,11.2953195 L11.4111874,11.2953195 C11.6612596,11.2953195 11.8637649,11.112845 11.8637649,10.8875087 L11.8651504,7.5994299 C11.8651504,7.49123519 11.8173527,7.38761795 11.7326098,7.31104938 C11.647867,7.23448082 11.5324136,7.191411 11.412342,7.191411 L8.02747792,7.191411 L8.02747792,5.26762585 C8.02747792,4.34297723 8.27200831,3.87357864 9.60872828,3.87357864 L11.5476534,3.87295444 C11.7974947,3.87295444 12,3.69027184 12,3.46514362 L12,0.411972161 C12,0.187052006 11.7977256,0.00457746845 11.5481152,0.00416133496 Z" id="Shape"></path>
                        </g>
                    </g>
                </g>
            </svg>
            Facebook
          </button>
        </div>
        <div>
          <p>
            Don’t have account ? Please&nbsp;
            <span
              className="link"
              onClick={() => {
                setLoginModalVisibility(false)
                setSignupModalVisibility(true)
              }}>Sign Up</span>
          </p>
          <p className="mt-5">
            <span
              className="link"
              onClick={() => {
                setLoginModalVisibility(false)
                setForgotPasswordModalVisibility(true)
              }}>Forgot password?</span>
          </p>
        </div>
      </div>
    )
  }
}
