/* global __CLIENT__ */

import React from 'react'
import moment from 'moment'
import classnames from 'classnames'
import DatePicker from 'react-datepicker'


// let DatePicker

// if (__CLIENT__) {
//   DatePicker = require('react-datepicker').default
// }

const renderDatePickerField = ({input, showError, meta: { dirty, touched, error }, ...props}) => {
  return (
  <div className={classnames('form-control-symbol', {
      error: (showError || touched) && error,
    })}>
    {
      /*__CLIENT__ ?*/
        <DatePicker
          selected={input.value && (moment.isMoment(input.value) ? input.value : moment(input.value)) || moment()}
          onChange={newVal => input.onChange(newVal)}
          dateFormat="LL"
          placeholderText="Pick a date"
          {...props}
          />
        /*: null*/
    }
    <div className="symbol"><i className="nc-icon-mini ui-1_calendar-57" /></div>
  </div>
  )
}

export default renderDatePickerField
