import React, { Component } from 'react'
import { Form, Field, reduxForm } from 'redux-form'

import * as helpers from '../../helpers'

import {
  renderPlaceholderTextField,
} from './fields'

const validate = (values, props) => {
  const errors = {}

  if (!values.email) {
    errors.email = 'Email is required'
  } else if (values.email && !helpers.isEmailValid(values.email)) {
    errors.email = 'Email is not valid'
  }

  if (!values.password) {
    errors.password = 'Password is required'
  } else if (values.password && values.password.length < 6) {
    errors.password = 'Password must be at least 6 characters long'
  }

  return errors
}

class LoginForm extends Component {
  render () {
    const {
      handleSubmit,
      loginFetching,
    } = this.props

    return <div>
      <Form className="form-content login-form"
        onSubmit={handleSubmit}
        noValidate>
        <div className="mb-20">
          <Field name="email" size="lg" component={renderPlaceholderTextField} placeholder={'Email'} type="email" autoFocus/>
        </div>
        <div className="mb-40">
          <Field name="password" size="lg" component={renderPlaceholderTextField} placeholder={'Password'} type="password"/>
        </div>
        <div>
          <button
            className="btn btn-lg btn-accent btn-round btn-shadow "
            disabled={loginFetching}>Login</button>
        </div>
      </Form>
    </div>
  }
}

export default reduxForm({
  form: 'login',
  validate,
  fields: ['email', 'password']
})(LoginForm)
