/* global stripe */

import React from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'

import * as actions from '../../actions'

@connect(state => ({
  user: state.user.user,
}), actions)
@autobind
export default class ContactModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      numberError: '',
      expiryError: '',
      cvcError: '',
      cardNumber: null,
    }
  }

  componentDidMount() {
    const elements = stripe.elements()
    const cardNumber = elements.create('cardNumber')
    const cardExpiry = elements.create('cardExpiry')
    const cardCvc = elements.create('cardCvc')

    cardNumber.mount('.credit-card-form #cardNumber')
    cardExpiry.mount('.credit-card-form #cardExpiry')
    cardCvc.mount('.credit-card-form #cardCvc')

    this.setState({
      cardNumber,
    })

    cardNumber.addEventListener('change', event => {
      if (event.error) {
        this.setState({
          numberError: event.error.message,
        })
      } else {
        this.setState({
          numberError: '',
        })
      }
    })

    cardExpiry.addEventListener('change', event => {
      if (event.error) {
        this.setState({
          expiryError: event.error.message,
        })
      } else {
        this.setState({
          expiryError: '',
        })
      }
    })

    cardCvc.addEventListener('change', event => {
      if (event.error) {
        this.setState({
          cvcError: event.error.message,
        })
      } else {
        this.setState({
          cvcError: '',
        })
      }
    })
  }

  submit() {
    const {
      cardNumber,
    } = this.state

    const {
      addCreditCard,
    } = this.props

    this.setState({
      disableAddButton: true,
    })

    stripe.createToken(cardNumber).then(result => {
      if (result.error) {
        this.setState({
          disableAddButton: false,
          numberError: result.error.message,
        })
      } else {
        // token generated
        var stripeToken = result.token.id

        addCreditCard({
          stripe_token: stripeToken,
        })
        // var user = $rootScope.user
        // user.stripe_token = stripe_token

        // UserService.updateUser(user).then(function (response) {
        //     var user = response.data

        //     localStorageService.set('pro_user', user)
        //     $rootScope.user = user
        //     $scope.closeThisDialog(response.data)
        // })
        // .catch(function () {
        //     notie.alert(1, 'Something went wrong, please try again!', 5)
        // })
      }
    })
  }

  render() {
    const {
      onRequestClose,
    } = this.props

    const {
      numberError,
      expiryError,
      cvcError,
      disableAddButton,
    } = this.state

    return (
      <div className="modal-container">
        <i className="close-icon nc-icon-outline ui-1_circle-remove visible-xs"
          onClick={onRequestClose}></i>
        <div className="credit-card-container credit-card-form">
          <h3 className="credit-card-title">Add Credit Card</h3>
          <div className="credit-card-input-wrapper mb-10">
            <label className="credit-card-label" htmlFor="cardNumber">Card number</label>
            <div id="cardNumber" className="credit-card-input"></div>
            <p className="credit-card-error">{numberError}</p>
          </div>
          <div className="row">
            <div className="credit-card-input-wrapper col-xs-6">
              <label className="credit-card-label" htmlFor="cardExpiry">Expiration Date</label>
              <div id="cardExpiry" className="credit-card-input"></div>
              <p className="credit-card-error">{expiryError}</p>
            </div>
            <div className="credit-card-input-wrapper col-xs-6">
              <label className="credit-card-label" htmlFor="cardCvc">CVC</label>
              <div id="cardCvc" className="credit-card-input"></div>
              <p className="credit-card-error">{cvcError}</p>
            </div>
          </div>
          <div className="submit-container">
            <button
              className="btn btn-round"
              disabled={disableAddButton}
              onClick={this.submit}
              >Add card</button>
          </div>
        </div>
      </div>
    )
  }
}
