import React, { Component } from 'react'
import { Form, Field, reduxForm } from 'redux-form'
import moment from 'moment'

import {
  renderPlaceholderTextareaField,
} from './fields'
import renderDatePickerField from './fields/renderDatePickerField'

const validate = (values, props) => {
  const errors = {}

  return errors
}

class RequestViewingForm extends Component {
  render () {
    const {
      handleSubmit,
      requestViewingPending,
    } = this.props

    return <div>
      <Form className="form-content login-form"
        onSubmit={handleSubmit}
        noValidate>
        <div className="primary uppercase bold pt-15 pb-15 hidden-xs">Viewing date & time</div>
        <Field
          name="date"
          component={renderDatePickerField}
          showTimeSelect
          timeFormat="LT"
          timeIntervals={30}
          dateFormat="LLL"
        />
        <div className="primary uppercase bold pt-15 pb-15 hidden-xs">Message</div>
        <Field name="message" component={renderPlaceholderTextareaField} placeholder="Additional information..." />
        <div className="right">
          <button className="btn mt-15" type="submit" disabled={requestViewingPending}>Send</button>
        </div>
      </Form>
    </div>
  }
}

export default reduxForm({
  form: 'requestViewing',
  validate,
  fields: ['datetime', 'message'],
  initialValues: {
    message: '',
    startDate: moment(),
  },
})(RequestViewingForm)
