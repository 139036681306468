import React from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'

import * as helpers from '../../helpers'
import * as actions from '../../actions'

@connect(state => ({
  rental: state.rental.listing,
  sale: state.sale.listing,
}), actions)
@autobind
export default class ListingShareModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    const {
      rental,
      sale,
      setListingShareModalVisibility,
    } = this.props

    const listing = rental && rental.id ? rental : sale

    return (
      <div className="listing-share-modal">
        <i
          className="close-icon nc-icon-outline ui-1_circle-remove"
          onClick={setListingShareModalVisibility.bind(this, false)}></i>
        <div className="bold uppercase center mb-20">Share with friends</div>
        <div className="dis-flex">
          <span
            className="btn btn-icon facebook-bg flex mr-10"
            onClick={helpers.facebookShareListing.bind(this, listing)}>
            <i className="nc-icon-mini social_logo-fb-simple"></i>
          </span>
          <span className="btn btn-icon twitter-bg flex"
            onClick={helpers.twitterShareListing.bind(this, listing)}>
            <i className="nc-icon-mini social_logo-twitter"></i>
          </span>
        </div>
        <div className="dis-flex mt-10">
          <span className="btn btn-icon linkedin-bg flex mr-10"
            onClick={helpers.linkedinShareListing.bind(this, listing)}>
            <i className="nc-icon-mini social_logo-linkedin"></i>
          </span>
          <span className="btn btn-icon light-bg flex"
            onClick={helpers.emailShareListing.bind(this, listing)}>
            <i className="nc-icon-mini ui-1_email-83"></i>
          </span>
        </div>
      </div>
    )
  }
}
