import React from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'

import * as actions from '../../actions'

import ViewingReviewForm from '../forms/ViewingReviewForm'

@connect(state => ({
  user: state.user.user,
  createViewingReviewPending: state.viewing.createViewingReviewPending,
  currentViewingReview: state.viewing.currentViewingReview,
}), actions)
@autobind
export default class ViewingReviewModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  handleSubmit(values) {
    const {
      createViewingReview,
      currentViewingReview,
    } = this.props

    createViewingReview({
      id: currentViewingReview,
      review: values,
    })
  }

  render() {
    const {
      user,
      createViewingReviewPending,
      setViewingReviewModalVisibility,
    } = this.props

    return (
      <div className="modal-container">
        <i className="close-icon nc-icon-outline ui-1_circle-remove visible-xs"
          onClick={setViewingReviewModalVisibility.bind(this, false)}></i>
        <ViewingReviewForm
          user={user}
          submitPending={createViewingReviewPending}
          onSubmit={this.handleSubmit} />
      </div>
    )
  }
}
