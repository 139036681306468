import React from 'react'
import autobind from 'autobind-decorator'

import * as helpers from '../helpers'

@autobind
export default class FallbackImage extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      fallbackSrc: null,
    }
  }

  changeFallbackSrc() {
    this.setState({
      fallbackSrc: this.props.fallbackSrc || helpers.listingPlaceholderImage,
    })
  }

  render() {
    const {
      src,
    } = this.props

    const {
      fallbackSrc,
    } = this.state

    return (
      <img
        {...this.props}
        alt={this.props.alt}
        src={fallbackSrc || src}
        onError={this.changeFallbackSrc}
      />
    )
  }
}
