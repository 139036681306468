import React, { Component } from 'react'
import { Form, Field, reduxForm } from 'redux-form'

import * as helpers from '../../helpers'

import {
  renderPlaceholderTextField,
} from './fields'

const validate = (values, props) => {
  const errors = {}

  if (!values.email) {
    errors.email = 'Email is required'
  } else if (values.email && !helpers.isEmailValid(values.email)) {
    errors.email = 'Email is not valid'
  }

  return errors
}

class ForgotPasswordForm extends Component {
  render () {
    const {
      handleSubmit,
      sendForgotPasswordFetching,
    } = this.props

    return <div>
      <Form className="form-content login-form"
        onSubmit={handleSubmit}
        noValidate>
        <div className="mb-40">
          <Field name="email" size="lg" component={renderPlaceholderTextField} placeholder="Email" type="email" autoFocus/>
        </div>
        <div>
          <button
            className="btn btn-lg btn-shadow btn-round btn-accent"
            disabled={sendForgotPasswordFetching}>Reset Password</button>
        </div>
      </Form>
    </div>
  }
}

export default reduxForm({
  form: 'login',
  validate,
  fields: ['email']
})(ForgotPasswordForm)
