import React from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'

import * as actions from '../../actions'

import BuildingReviewForm from '../forms/BuildingReviewForm'

@connect(state => ({
  user: state.user.user,
  createBuildingReviewPending: state.building.createBuildingReviewPending,
  currentBuildingId: state.building.building && state.building.building.id,
}), actions)
@autobind
export default class BuildingReviewModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  handleSubmit(values) {
    const {
      createBuildingReview,
      currentBuildingId,
      user,
    } = this.props

    createBuildingReview({
      building_id: currentBuildingId,
      user_id: !user.is_pro ? user.id : null,
      pro_user_id: user.is_pro ? user.id : null,
      location_rating: values.locationRating,
      amenities_rating: values.amenitiesRating,
      neighborhs_rating: values.neighborsRating,
      management_rating: values.managementRating,
      safety_rating: values.safetyRating,
      value_rating: values.valueRating,
      headline: values.headline,
      description: values.description,
    })
  }

  render() {
    const {
      user,
      createBuildingReviewPending,
      setBuildingReviewModalVisibility,
    } = this.props

    return (
      <div className="modal-container">
        <i className="close-icon nc-icon-outline ui-1_circle-remove visible-xs"
          onClick={setBuildingReviewModalVisibility.bind(this, false)}></i>
        <BuildingReviewForm
          user={user}
          submitPending={createBuildingReviewPending}
          onSubmit={this.handleSubmit} />
      </div>
    )
  }
}
